/* Estilos para las pestañas */
.custom-tabs {
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,.1);
  padding: 0px;
  margin-bottom: 15px;

  overflow-x: auto;
  max-width: 100%
}

.custom-tab {
  padding: .5rem 1.125rem;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  border-radius: 5px 5px 0px 0px;
}

.custom-tab.active {
  background-color: #4679cc;
  color: #fff;
}

.custom-tab:hover {
  background-color: #3366b9;
  color: #fff;
}
