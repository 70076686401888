.indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scale(2);
}
.indicator svg polyline {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.indicator svg polyline#back {
  stroke: rgba(#3BD3AB,.3);
}
.indicator svg polyline#front {
  stroke: #3BD3AB;
  animation: dash 1s linear infinite;
}

@keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.cta {
  position: fixed;
  bottom: 20px;
  right: 30px;
  color: #222;
  font-weight: bold;
}
