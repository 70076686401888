.vertical-menu{
    padding-right: 0;
}
.content{
    padding-left: 0;
}
.nav {
    background-color: #BDD8DB;
    height: 100%;
    justify-content: center;
}
.nav-tabs .nav-link{
    height: 4rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0;
    text-align: center;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    border-left: 10px solid #2b5884; 
    background-color: #ddedf1;
}
.nav-link:hover {
    background-color: #ddedf1;
}
@media screen and (max-width: 767px) {
    .content{
        width: 100%;
        padding-left: 10px;
        height: 100%;
    }
    .mobile-select {
        display: block;
    }

    .mobile-select select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("https://cdn-icons-png.flaticon.com/512/32/32195.png");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 18px;
        padding-right: 25px;
        padding-left: 15px;
        width: 100%;
        font-size: 1rem;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        height: 38px;
      }
}

