.profile-image{
  background-image: url(./avatar-c.png);
}
.profile-image-md{
    background-image: url(./avatar-c.png);
    background-size: cover;
}
.img-share{
  background-image: url(./pic-7.png);
  background-size: cover;
}
.progress-bar-45{
  width: 45%
}
