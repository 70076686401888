.container-menu{
    padding: 0;
    width: 20%;
}
.container-body{
    width: 80%;
}
hr{
    border: 1px solid #ccc;
    margin-bottom: 2px;
    margin-top: 5px;
}
.nav {
    background-color: #BDD8DB;
    height: 100%;
    justify-content: center;
    padding-right: 10px;
}
.nav-title-item{
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 1rem 0 1rem;
    border: none;
    border-radius: 0;
}
.nav-title-item.active{
    text-decoration: underline;
}
li.nav-item{
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
}
.nav-link{
    height: 2rem;
    cursor: pointer;
    padding: 0.5rem;
    padding-left: 35px;
    border: none;
    border-radius: 0;
    color: #2c5b84;
}
.nav-link:hover{
    background-color: #83c4cc;
}
a.active.nav-link {
    background-color: #83c4cc;
    font-weight: 800;
}
a.active.nav-link li {
    font-weight: 800;
}